import { createAsyncThunk } from '@reduxjs/toolkit';
import { thunkExecuteAndReturnResultOrShowError } from '../../services/base.service';
import {
  getUserById,
  getUserEditDetail,
  getUserList,
} from '../../services/user.service';
import { UserDetailViewModel } from '../../types/UserDetailModel';
import { UserEditViewModel } from '../../types/UserEditViewModel';
import { UserListModel } from '../../types/UserListModel';

export const fetchUserList = createAsyncThunk(
  'user/fetchUserList',
  async (_, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<UserListModel>(
      thunkAPI,
      getUserList({ signal: thunkAPI.signal })
    );
  }
);

export const fetchUserById = createAsyncThunk(
  'user/fetchUserById',
  async (userId: string, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<UserDetailViewModel>(
      thunkAPI,
      getUserById(userId, { signal: thunkAPI.signal })
    );
  }
);

export const fetchEditUserDetailsById = createAsyncThunk(
  'user/fetchEditUserDetailsById',
  async (userId: string, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<UserEditViewModel>(
      thunkAPI,
      getUserEditDetail(userId, { signal: thunkAPI.signal })
    );
  }
);
