import {
  SchoolAssignmentDetail,
  UserDetailFormModel,
  UserDetailViewModel,
} from '../types/UserDetailModel';
import { SchoolBasedPermission } from '../types/UserEditViewModel';

export const mapToFormModel = (
  details: UserDetailViewModel
): UserDetailFormModel => {
  const assignments: SchoolAssignmentDetail[] = [];

  const getAdditionalPermissions = (
    permission: SchoolBasedPermission
  ): string => {
    const items: string[] = [];
    if (permission.canCertifyDistrict) {
      items.push('Can Certify District');
    }
    if (permission.canCertifySchools) {
      items.push('Can Certify Schools');
    }
    if (permission.msisIdRequestAccess) {
      items.push('MSIS ID Request');
    }
    if (permission.studentOwnershipAccess) {
      items.push('Student Ownership');
    }
    return items.join(', ');
  };

  for (const [key, value] of Object.entries(details.schoolBasedPermissions)) {
    const schoolList = value.schools
      .map((school) => `${school.name} (${school.schoolId})`)
      .join(', ');
    assignments.push({
      role: key,
      schools: schoolList,
      additionalPermissions: getAdditionalPermissions(value),
    });
  }

  return { ...details, schoolAssignments: assignments };
};
