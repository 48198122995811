/* eslint-disable max-len */
import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import NavigationPage from '../../components/NavigationPage/NavigationPage';
import { appRoutePaths } from '../../services/route.service';
import './AccreditationEdits.css';

const AccreditationEdits = (): ReactElement => {
  const { category } = useParams();
  const [title, setTitle] = useState<string>('');

  useEffect(() => {
    switch (category) {
      case 'school':
        setTitle('School Edits');
        break;
      case 'lea':
        setTitle('LEA Edits');
        break;
      case 'personnel':
        setTitle('Personnel Edits');
        break;
    }
  }, [category]);

  return (
    <NavigationPage
      heading={title}
      pageClass="data-edits--detail"
      isLoading={false}
      loadingDataId="data-edits-detail-loader"
      loadingText="Getting your details"
      backBarLocation={appRoutePaths.Accreditation}
    >
      <></>
    </NavigationPage>
  );
};

export default AccreditationEdits;
