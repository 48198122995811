import { ReactElement, useEffect } from 'react';
import Bubble from '../../components/Bubble/Bubble';
import EmptyGridContent from '../../components/EmptyGridContent/EmptyGridContent';
import NavigationPage from '../../components/NavigationPage/NavigationPage';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectDashboardItems,
  selectDataQualityLoading,
} from '../../redux/slices/dataQualitySlice';
import { fetchDataQualityData } from '../../redux/thunks/dataQualityThunks';
import {
  abortPromiseOnUnmount,
  getCurrentUserAccess,
} from '../../services/base.service';
import { appRoutePaths } from '../../services/route.service';
import { DataQualityDashboardModel } from '../../types/DataQualityDashboardModel';
import { IconType } from '../../types/propTypes/EmptyGridPropTypes';
import './DataQualityDashboard.css';

const DataQualityDashboard = (): ReactElement => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectDataQualityLoading);
  const items: DataQualityDashboardModel[] =
    useAppSelector(selectDashboardItems);

  useEffect(() => {
    const promise = dispatch(fetchDataQualityData());
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch]);

  return (
    <NavigationPage
      pageClass="data-quality"
      heading={`${getCurrentUserAccess()?.currentDistrict?.name}`}
      isLoading={isLoading}
      loadingDataId="data-quality-loader"
      loadingText="Getting your items"
    >
      {items.map((item, index) => (
        <Bubble
          key={index}
          count={item.errorCount}
          title={item.displayText}
          bubbleText="Error(s)"
          navigateTo={appRoutePaths.DataQualityDetail(item.domain)}
          visibilityOverrideRoute={appRoutePaths.DataQualityDetail()}
        />
      ))}
      {items.length === 0 && (
        <EmptyGridContent iconType={IconType.FILE}>
          <p>You currently have no errors</p>
        </EmptyGridContent>
      )}
    </NavigationPage>
  );
};

export default DataQualityDashboard;
