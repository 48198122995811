import { ReactElement } from 'react';
import './Unauthorized.css';

const Unauthorized = (): ReactElement => {
  return (
    <div className="page--unauthorized">
      <section className="page--unauthorized-section">
        <h1>403</h1>
        <h4>Access Denied</h4>
        <div className="content">
          Sorry, but you don&apos;t have permission to access this page.
        </div>
      </section>
    </div>
  );
};

export default Unauthorized;
