import { ApiResponseModel } from '../types/ApiResponseModel';
import { CertificationDetailModel } from '../types/CertificationDetailModel';
import { CertificationViewModel } from '../types/CertificationViewModel';
import { DataRequestConfig } from '../types/DataRequestConfig';
import { getApiBaseUrl } from './app.service';
import { executeApiRequest, getAuthToken } from './base.service';
import { getHttpClient, HttpClient } from './httpClient';

export const certificationApiPaths = {
  getViewModel: 'certification/form',
  getDetails: (schoolYear: string, dataCollectionId: string): string =>
    `certification/${schoolYear}/${dataCollectionId}`,
};

export const CertificationServiceClient = (
  config?: DataRequestConfig
): HttpClient =>
  getHttpClient(getApiBaseUrl(), getAuthToken() || '', config?.signal);

export const getCertificationViewModel = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<CertificationViewModel>> => {
  return executeApiRequest<CertificationViewModel>(async () => {
    return await CertificationServiceClient(config).get(
      certificationApiPaths.getViewModel
    );
  });
};

export const getCertificationDataCollectionDetails = async (
  schoolYear: string,
  dataCollectionId: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<CertificationDetailModel>> => {
  return executeApiRequest<CertificationDetailModel>(async () => {
    return await CertificationServiceClient(config).get(
      certificationApiPaths.getDetails(schoolYear, dataCollectionId)
    );
  });
};
