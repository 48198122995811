import { appRoutePaths } from '../services/route.service';

export interface NavigationTabItem {
  name: string;
  routePath: string;
}

export const Tabs_MDE_OTSS = [
  {
    name: 'Course Codes',
    routePath: appRoutePaths.CourseCodeList,
  },
  {
    name: 'SEA Organizations',
    routePath: appRoutePaths.SeaOrganizationList,
  },
];

export const Tabs_DISTRICT_SCHOOL = [
  {
    name: 'LEA Management',
    routePath: appRoutePaths.LEAManagementList,
  },
  {
    name: 'School Management',
    routePath: appRoutePaths.SchoolManagementList,
  },
  {
    name: 'LEA Sharing',
    routePath: appRoutePaths.LEASharingList,
  },
  {
    name: 'Salary Scale',
    routePath: appRoutePaths.SalaryScaleList,
  },
];

export const Tabs_STUDENT = [
  {
    name: 'Non Public Student',
    routePath: appRoutePaths.NonPublicStudentEntryList,
  },
];
