import { ReactElement } from 'react';

const SchoolCertificationGrid = (): ReactElement => {
  return (
    <div>
      <table cellSpacing={0} className="page-view-table">
        <thead>
          <tr data-testid="grid-headers">
            <th>School Number</th>
            <th>School Name</th>
            <th>Certification Date</th>
            <th>Certified By</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
  );
};

export default SchoolCertificationGrid;
