/* eslint-disable max-len */
import { ReactElement } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import {
  selectCollectionDetail,
  selectDetailLoading,
} from '../../../redux/slices/certificationSlice';
import { CertificationDetailModel } from '../../../types/CertificationDetailModel';
import { PanelType } from '../../../types/PanelType';
import { formatToLongDateString } from '../../../utilities/dateUtilities';
import Loader from '../../Loader/Loader';
import Panel from '../../Panel/Panel';
import DistrictCertificationGrid from '../DistrictCertificationGrid/DistrictCertificationGrid';
import SchoolCertificationGrid from '../SchoolCertificationGrid/SchoolCertificationGrid';
import './CertificationCollectionDetail.css';

const CertificationCollectionDetail = (): ReactElement => {
  const isDetailLoading = useAppSelector(selectDetailLoading);
  const detail: CertificationDetailModel = useAppSelector(
    selectCollectionDetail
  );

  return (
    <div className="certification-detail">
      {isDetailLoading ? (
        <div className="loading-container">
          <Loader
            dataTestId={'loading-detail'}
            message={'Loading Collection Data Points...'}
          />
        </div>
      ) : (
        <div className="detail-container">
          <div
            className="data-collection--heading data-section"
            data-testid="collection-heading"
          >
            {detail.headingText}
          </div>
          <div className="data-collection--dates data-section">
            <div
              className="collection--label date-start-date"
              data-testid="collection-open-date"
            >
              Certification Open Date:{' '}
              {formatToLongDateString(detail.certificationOpenDate)}
            </div>
            <div
              className="collection--label"
              data-testid="collection-close-date"
            >
              Certification Close Date:{' '}
              {formatToLongDateString(detail.certificationCloseDate)}
            </div>
          </div>
          <Panel
            heading="Monthly Certification Reports"
            panelType={PanelType.INFO}
          >
            <></>
          </Panel>
          {detail.isCertifiableBySchool && (
            <div
              className="data-collection--list data-section"
              data-testid="certification-school-list"
            >
              <label className="collection--label">School Certification</label>
              <SchoolCertificationGrid />
            </div>
          )}
          <div
            className="data-collection--list"
            data-testid="certification-district-list"
          >
            <label className="collection--label">District Certification</label>
            <DistrictCertificationGrid />
          </div>
        </div>
      )}
    </div>
  );
};

export default CertificationCollectionDetail;
