/* eslint-disable max-len */
import { ReactElement } from 'react';
import DistrictSchoolInfoPage from '../DistrictSchoolInfoPage';

const SalaryScaleList = (): ReactElement => {
  return (
    <DistrictSchoolInfoPage
      pageClass="data-input--district-school-info--salary-scale--list"
      isLoading={false}
      loadingDataId={'salary_scale-list-loader'}
    >
      <div className="content-outer-container">
        <div className="content-inner-container">
          <div className="subheading-and-button-container">
            <h4 data-testid={'component-heading-text'}>Salary Scale</h4>
          </div>
        </div>
      </div>
    </DistrictSchoolInfoPage>
  );
};

export default SalaryScaleList;
