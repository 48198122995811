import { createSlice } from '@reduxjs/toolkit';
import { mapToFormModel } from '../../mappers/UserDetailMapper';
import { UserDetailFormModel } from '../../types/UserDetailModel';
import { UserEditViewModel } from '../../types/UserEditViewModel';
import { UserListItem } from '../../types/UserListModel';
import { UserStatus } from '../../types/UserStatus';
import { UserType } from '../../types/UserType';
import { isEmptyObject } from '../../utilities/helperUtilities';
import type { RootState } from '../store';
import {
  fetchEditUserDetailsById,
  fetchUserById,
  fetchUserList,
} from '../thunks/userThunks';

interface UserState {
  loading: boolean;
  users: UserListItem[];
  user: UserDetailFormModel;
  editUser: UserEditViewModel;
}

export const defaultUserEditViewModel: UserEditViewModel = {
  user: {
    name: '',
    email: '',
    userStatus: UserStatus.InActive,
    currentDistrict: undefined,
    assignedRoles: [],
    schoolBasedPermissions: {},
    userType: UserType.District,
    isAdmin: false,
  },
  assignableRoles: [],
  assignableSchools: [],
  assignableSchoolBasedPermissions: [],
};

export const defaultUserDetailFormModel: UserDetailFormModel = {
  userId: '',
  name: '',
  email: '',
  userStatus: UserStatus.InActive,
  currentDistrict: undefined,
  assignedRoles: [],
  schoolAssignments: [],
  userType: UserType.District,
};

export const initialState: UserState = {
  loading: false,
  users: [],
  user: defaultUserDetailFormModel,
  editUser: defaultUserEditViewModel,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: { clearUserState: () => initialState },
  extraReducers: (builder) => {
    builder.addCase(fetchUserList.pending, (state) => {
      state.users = [];
      state.loading = true;
    });
    builder.addCase(fetchUserList.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        state.users = action.payload.users;
      }
      state.loading = false;
    });
    builder.addCase(fetchUserList.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loading = false;
      }
    });
    builder.addCase(fetchUserById.pending, (state) => {
      state.user = defaultUserDetailFormModel;
      state.loading = true;
    });
    builder.addCase(fetchUserById.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        state.user = mapToFormModel(action.payload);
      }
      state.loading = false;
    });
    builder.addCase(fetchUserById.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loading = false;
      }
    });
    builder.addCase(fetchEditUserDetailsById.pending, (state) => {
      state.editUser = defaultUserEditViewModel;
      state.loading = true;
    });
    builder.addCase(fetchEditUserDetailsById.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        state.editUser = action.payload;
      }
      state.loading = false;
    });
    builder.addCase(fetchEditUserDetailsById.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loading = false;
      }
    });
  },
});

export const { clearUserState } = userSlice.actions;

export const selectUserLoading = (state: RootState): boolean =>
  state.user.loading;
export const selectUserList = (state: RootState): UserListItem[] =>
  state.user.users;
export const selectUser = (state: RootState): UserDetailFormModel =>
  state.user.user;
export const selectEditUserDetails = (state: RootState): UserEditViewModel =>
  state.user.editUser;

export default userSlice.reducer;
