/* eslint-disable max-len */
import { ReactElement, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SingleValue } from 'react-select';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectSelectedDistrict,
  setSelectedDistrict,
} from '../../redux/slices/appSlice';
import { errorLoaded } from '../../redux/slices/errorSlice';
import { updateCurrentUserAccess } from '../../redux/slices/loginSlice';
import { TooltipAnchor } from '../../services/app.service';
import {
  getCurrentUserAccess,
  isValidCode,
  setCurrentUserAccess,
} from '../../services/base.service';
import { updateCurrentDistrict } from '../../services/user.service';
import { DistrictModel } from '../../types/DistrictModel';
import { ReactSelectOption } from '../../types/ReactSelectOption';
import { SelectOption } from '../../types/SelectOption';
import { setStyledDropdownSingleValue } from '../../utilities/helperUtilities';
import { isDistrictRefreshRoute } from '../../utilities/routeUtilities';
import { canChangeViewingDistrict } from '../../utilities/userUtilities';
import StyledSingleDropdown from '../StyledSingleDropdown/StyledSingleDropdown';
import './UserDistrictList.css';

const UserDistrictList = (): ReactElement => {
  const user = getCurrentUserAccess();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const selectedDistrict = useAppSelector(selectSelectedDistrict);

  const [isLoading, setIsLoading] = useState(false);

  const selectedDistrictName = selectedDistrict.name;
  const selectedDistrictId = selectedDistrict.districtId;
  const tooltipText = 'Page showing data for this district';
  const showDistrictDropdown =
    isDistrictRefreshRoute(location.pathname) && selectedDistrictId !== '';
  const renderList = canChangeViewingDistrict() && user?.allowedDistricts;

  // On full reload, state gets dumped so we need to pull
  // the values from session and set them into state again
  useEffect(() => {
    if (selectedDistrictName === '' && user?.currentDistrict) {
      dispatch(updateCurrentUserAccess(user));
      dispatch(setSelectedDistrict(user.currentDistrict));
    }
  }, [dispatch, selectedDistrictName, user]);

  const getDistrictSelectOptions = (): SelectOption[] => {
    return (
      /* istanbul ignore next */
      user?.allowedDistricts?.map((district): SelectOption => {
        return {
          text: `${district.name} (${district.districtId})`,
          value: district.districtId,
        };
      }) || []
    );
  };

  const handleOnChangeSuccess = async (
    selected: SingleValue<ReactSelectOption>
  ): Promise<void> => {
    const dataValue = selected?.value;
    setIsLoading(true);

    /* istanbul ignore next */
    if (user && dataValue) {
      const dropdownDistrict = user.allowedDistricts.find(
        (dm: DistrictModel) => dm.districtId === dataValue
      );

      if (
        dropdownDistrict &&
        dropdownDistrict.districtId != selectedDistrictId
      ) {
        const response = await updateCurrentDistrict(dataValue.toString());

        if (isValidCode(response.status)) {
          setCurrentUserAccess(response.data);

          // Will force a full page refresh
          // NOTE: This will dump all redux state
          navigate(0);
        } else {
          dispatch(errorLoaded(response.error));
        }
      }
    }

    setIsLoading(false);
  };

  return (
    <div className="list-selection">
      {renderList ? (
        <div
          className="district-selection-container"
          data-tooltip-id={TooltipAnchor}
          data-tooltip-content={tooltipText}
        >
          {showDistrictDropdown && (
            <StyledSingleDropdown
              fieldName="currentDistrict"
              options={getDistrictSelectOptions()}
              onChange={handleOnChangeSuccess}
              disabled={isLoading}
              isLoading={isLoading}
              value={setStyledDropdownSingleValue(
                getDistrictSelectOptions(),
                selectedDistrictId
              )}
              // -- Props to make sure over sidebar menu options
              menuPortalTarget={document.body}
              styles={{
                menuPortal:
                  /* istanbul ignore next */
                  (base: Object) => ({
                    ...base,
                    zIndex: 999,
                  }),
              }}
              // --
            />
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default UserDistrictList;
