import { DateTime } from 'luxon';

export const formatToDateString = (utcString: string | DateTime): string => {
  let formattedString = '';
  if (utcString instanceof DateTime) {
    return utcString.toLocaleString(DateTime.DATE_SHORT, { locale: 'utc' });
  } else {
    const dtInGMT = DateTime.fromISO(utcString);
    if (dtInGMT.isValid) {
      formattedString = dtInGMT.toLocaleString(DateTime.DATE_SHORT, {
        locale: 'utc',
      });
    }
  }
  return formattedString;
};

export const formatToLongDateString = (
  utcString: string | DateTime
): string => {
  let formattedString = '';
  if (utcString instanceof DateTime) {
    return utcString.toLocaleString(DateTime.DATE_FULL, { locale: 'utc' });
  } else {
    const dtInGMT = DateTime.fromISO(utcString);
    if (dtInGMT.isValid) {
      formattedString = dtInGMT.toLocaleString(DateTime.DATE_FULL, {
        locale: 'utc',
      });
    }
  }
  return formattedString;
};

export const formatJSDateToDateString = (date?: Date): string => {
  let formattedString = '';
  if (date) {
    formattedString = date.toISOString().split('T')[0];
  }
  return formattedString;
};

export const formatToDateTimeString = (utcString: string): string => {
  let formattedString = '';
  const usCentralTimeZone = 'America/Chicago';
  if (utcString) {
    const dtInGMT = DateTime.fromISO(utcString, { setZone: true });
    if (dtInGMT.isValid) {
      formattedString = dtInGMT.setZone(usCentralTimeZone).toLocaleString({
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
      });
    }
  }

  return formattedString;
};

export const addDays = (date: Date, days: number): Date => {
  const result = new Date(date);
  result.setDate(date.getDate() + days);
  return result;
};

export const getAgeByDate = (birthdate: string, ageOnDate?: string): number => {
  let value = 0;
  const birthDate = DateTime.fromFormat(birthdate, 'yyyy-MM-dd');
  if (ageOnDate) {
    const onDate = DateTime.fromFormat(ageOnDate, 'yyyy-MM-dd');
    value = birthDate.diff(onDate, 'years').rescale().as('years');
  } else {
    value = birthDate.diffNow('years').rescale().as('years');
  }

  // This will get a negative result
  const age = parseInt(Math.abs(value).toString());
  if (Number.isNaN(age)) {
    return 0;
  }

  return age;
};
