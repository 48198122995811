/* eslint-disable max-len */
import { MouseEventHandler, ReactElement, useEffect, useState } from 'react';
import { FileUploadLegend } from '../../components/FileUpload/FileUploadLegend/FileUploadLegend';
import NotAbleToUpload from '../../components/FileUpload/NotAbleToUpload/NotAbleToUpload';
import ReadyToUpload from '../../components/FileUpload/ReadyToUpload/ReadyToUpload';
import SelectFiles from '../../components/FileUpload/SelectFiles/SelectFiles';
import FileUploadStatus from '../../components/FileUploadStatus/FileUploadStatus';
import NavigationButton from '../../components/NavigationButton/NavigationButton';
import NavigationPage from '../../components/NavigationPage/NavigationPage';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectFileLoading,
  selectFilePermissions,
  selectFileUploadFormModel,
} from '../../redux/slices/fileSlice';
import {
  fetchPermissionsMap,
  fetchUploadFormModel,
} from '../../redux/thunks/filesThunks';
import { abortPromiseOnUnmount } from '../../services/base.service';
import { upload } from '../../services/file.service';
import { appRoutePaths } from '../../services/route.service';
import { ApiResponseModel } from '../../types/ApiResponseModel';
import { FileDetail } from '../../types/FileDetail';
import { FileError } from '../../types/FileError';
import { FilePermissionMap } from '../../types/FilePermissionMap';
import { FileUploadFormModel } from '../../types/FileUploadFormModel';
import { validateFiles } from '../../utilities/uploadUtilities';
import './FileUpload.css';

const FileUpload = (): ReactElement => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectFileLoading);
  const isLoadingPermissions = useAppSelector(selectFileLoading);
  const formModel: FileUploadFormModel = useAppSelector(
    selectFileUploadFormModel
  );
  const filePermissions: FilePermissionMap[] = useAppSelector(
    selectFilePermissions
  );

  const [unfilteredFiles, setUnfilteredFiles] = useState<File[]>([]);
  const [filteredFiles, setFilteredFiles] = useState<File[]>([]);
  const [filteredOutFiles, setFilteredOutFiles] = useState<File[]>([]);
  const [filteredOutFilesErrors, setFilteredOutFilesErrors] = useState<
    string[]
  >([]);
  const [solutionList, setSolutionList] = useState<string[]>([]);

  const [files, setFiles] = useState<FileDetail[]>([]);
  const [fileErrors, setFileErrors] = useState<FileError[]>([]);
  const [loaded, setLoaded] = useState(0);
  const [total, setTotal] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [uploadState, setUploadState] = useState('idle');
  const [userAllowedFileEndings, setUserAllowedFileEndings] = useState<
    string[]
  >([]);
  const fileUploadFormModelDefault: FileUploadFormModel = {
    allowedFileEndings: [],
    allowedFileExtensions: [],
  };

  const [userAllowedFormModel, setUserAllowedFormModel] =
    useState<FileUploadFormModel>(fileUploadFormModelDefault);

  const filterFiles = (): void => {
    const { validFiles, invalidFiles, errorMessages, solutions } =
      validateFiles(unfilteredFiles, formModel.allowedFileEndings);
    setFilteredFiles(validFiles);
    setFilteredOutFiles(invalidFiles);
    setFilteredOutFilesErrors(errorMessages);
    setSolutionList(solutions);
  };

  function aggregateExtensions(
    _filePermissions: FilePermissionMap[]
  ): string[] {
    return _filePermissions.flatMap((permission) => permission.fileExtensions);
  }

  const progressHandler = (e: ProgressEvent): void => {
    setLoaded(e.loaded);
    setTotal(e.total);
  };

  const completeHandler = (response: ApiResponseModel<unknown>): void => {
    if (response.status >= 400) {
      setUploadState('failed');
      if (response.error.dataObj) {
        setFileErrors(response.error.dataObj as FileError[]);
      } else {
        setFileErrors(
          filteredFiles.map(
            (): FileError => ({
              errors: ['There was an error uploading the file.'],
            })
          )
        );
      }
    } else {
      setUploadState('done');
    }
  };

  const errorHandler = (): void => {
    setUploadState('failed');
  };

  const uploadAgainHandler = (e: MouseEvent): void => {
    e.stopPropagation();
    e.preventDefault();

    setUnfilteredFiles([]);
    setFiles([]);
  };

  const submitHandler = (e: MouseEvent): void => {
    e.stopPropagation();
    e.preventDefault();

    setFiles(
      filteredFiles.map(
        (file, i): FileDetail => ({
          name: file.name,
          progress: 0,
          size: file.size,
          key: i.toString(),
        })
      )
    );

    setUploadState('uploading');

    upload({
      files: filteredFiles,
      onProgress: progressHandler,
      onError: errorHandler,
      onDone: completeHandler,
    });
  };

  useEffect(() => {
    if (uploadState === 'uploading') {
      setIsFailed(false);
      setIsCompleted(false);
    } else if (uploadState === 'done') {
      setIsFailed(false);
      setIsCompleted(true);
      setLoaded(1);
      setTotal(1);
    } else if (uploadState === 'failed') {
      setIsFailed(true);
    }
  }, [uploadState]);

  useEffect(() => {
    filterFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unfilteredFiles]);

  useEffect(() => {
    const filterUserFiles = (): void => {
      setUserAllowedFileEndings(
        filePermissions.map((filePermission) => filePermission.fileType)
      );
    };
    filterUserFiles();
  }, [filePermissions, formModel]);

  useEffect(() => {
    setUserAllowedFormModel({
      ...formModel,
      allowedFileEndings: userAllowedFileEndings,
      allowedFileExtensions: aggregateExtensions(filePermissions),
    });
  }, [userAllowedFileEndings, formModel, filePermissions]);

  useEffect(() => {
    const promise = dispatch(fetchUploadFormModel());
    dispatch(fetchPermissionsMap());
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch]);

  let showSelectFiles = false;
  let showReadyToUpload = false;
  let showNotAbleToUpload = false;
  let showClearFiles = false;
  let showUploadFiles = false;
  let showRetryOptions = false;

  const hasValidFilesToUpload = filteredFiles && filteredFiles.length > 0;
  const hasNoValidFilesReadyToUpload = !hasValidFilesToUpload;
  const hasErrorFilesToUpload = filteredOutFiles && filteredOutFiles.length > 0;
  const hasNoErrorFilesReadyToUpload = !hasErrorFilesToUpload;
  const hasNoValidFileButHasErrorFiles =
    hasNoValidFilesReadyToUpload && hasErrorFilesToUpload;

  if (files.length === 0) {
    showSelectFiles =
      unfilteredFiles.length === 0 ||
      (hasNoValidFilesReadyToUpload && hasNoErrorFilesReadyToUpload);
    showReadyToUpload = hasValidFilesToUpload;
    showNotAbleToUpload = hasErrorFilesToUpload;
    showClearFiles = hasNoValidFileButHasErrorFiles || hasValidFilesToUpload;
    showUploadFiles = hasValidFilesToUpload;
  }
  showRetryOptions = files.length > 0;

  return (
    <NavigationPage
      heading={'Upload a New File'}
      pageClass="files--upload"
      isLoading={isLoading || isLoadingPermissions}
      loadingDataId="file-upload-loader"
      loadingText="Getting upload details"
    >
      {userAllowedFormModel && (
        <FileUploadLegend formModel={userAllowedFormModel} />
      )}
      {showSelectFiles && <SelectFiles onChange={setUnfilteredFiles} />}
      {showReadyToUpload && (
        <ReadyToUpload files={filteredFiles} onChange={setFilteredFiles} />
      )}
      {showNotAbleToUpload && (
        <NotAbleToUpload
          files={filteredOutFiles}
          errors={filteredOutFilesErrors}
          solutions={solutionList}
        />
      )}
      {(showClearFiles || showUploadFiles) && (
        <div className="files--upload--buttons">
          {showClearFiles && (
            <button
              data-cy="clear-files-button"
              className="button button--large no-wrap-text"
              onClick={() => setUnfilteredFiles([])}
            >
              Clear Files
            </button>
          )}
          {showUploadFiles && (
            <button
              data-cy="upload-files-button"
              className="button button--large button--secondary no-wrap-text"
              onClick={
                submitHandler as unknown as MouseEventHandler<HTMLButtonElement>
              }
            >
              Upload {filteredFiles.length}{' '}
              {filteredFiles.length > 1 ? 'Files' : 'File'}
            </button>
          )}
        </div>
      )}
      <div className="files--upload--statuses">
        {files.map((file, i) => {
          let percent = 0;
          if (isCompleted) {
            percent = 1;
          } else if (total) {
            percent = loaded / total;
          }
          let error = '';
          if (
            fileErrors[i] &&
            fileErrors[i].errors &&
            fileErrors[i].errors.length
          ) {
            // eslint-disable-next-line prefer-destructuring
            error = fileErrors[i].errors[0];
          }
          return (
            <FileUploadStatus
              name={file.name}
              percent={percent}
              isFailed={isFailed}
              isCompleted={isCompleted}
              error={error}
              key={file.key}
            />
          );
        })}
      </div>
      {showRetryOptions && (isCompleted || isFailed) && (
        <div className="files--upload--retry">
          {isCompleted && (
            <NavigationButton
              route={appRoutePaths.SubmissionStatus}
              classes="button button--large button--secondary no-wrap-text"
            >
              <>View Files</>
            </NavigationButton>
          )}
          <button
            className="button button--large no-wrap-text"
            onClick={
              uploadAgainHandler as unknown as MouseEventHandler<HTMLButtonElement>
            }
          >
            {isFailed ? 'Try Again' : 'Upload More Files'}
          </button>
        </div>
      )}
    </NavigationPage>
  );
};

export default FileUpload;
