import { ApiResponseModel } from '../types/ApiResponseModel';
import { DataRequestConfig } from '../types/DataRequestConfig';
import {
  CurrentUserDetailViewModel,
  UserDetailViewModel,
} from '../types/UserDetailModel';
import { UserEditModel } from '../types/UserEditModel';
import { UserEditViewModel } from '../types/UserEditViewModel';
import { UserListModel } from '../types/UserListModel';
import { getApiBaseUrl } from './app.service';
import { executeApiRequest, getAuthToken } from './base.service';
import { getHttpClient, HttpClient } from './httpClient';

export const userApiPaths = {
  updateCurrentDistrict: (districtId: string): string =>
    `currentuser/current-district/${districtId}`,
  getCurrentUserInfo: (updateLogin: boolean) =>
    `currentuser?updateLogin=${updateLogin}`,
  getUserList: 'user',
  getUserById: (userId: string): string => `user/${userId}`,
  updateUser: (userId: string): string => `user/edit/${userId}`,
  editUserDetails: (userId: string): string => `user/edit/${userId}`,
  activateUser: (userId: string): string => `user/activate/${userId}`,
  deactivateUser: (userId: string): string => `user/deactivate/${userId}`,
};

export const UserServiceClient = (config?: DataRequestConfig): HttpClient =>
  getHttpClient(getApiBaseUrl(), getAuthToken() || '', config?.signal);

export const getCurrentUser = async (
  config?: DataRequestConfig,
  updateLogin = true
): Promise<ApiResponseModel<CurrentUserDetailViewModel>> => {
  return executeApiRequest<CurrentUserDetailViewModel>(async () => {
    return await UserServiceClient(config).get(
      userApiPaths.getCurrentUserInfo(updateLogin)
    );
  });
};

export const getUserList = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<UserListModel>> => {
  return executeApiRequest<UserListModel>(async () => {
    return await UserServiceClient(config).get(userApiPaths.getUserList);
  });
};

export const getUserById = async (
  userId: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<UserDetailViewModel>> => {
  return executeApiRequest<UserDetailViewModel>(async () => {
    return await UserServiceClient(config).get(
      userApiPaths.getUserById(userId)
    );
  });
};

export const getUserEditDetail = async (
  userId: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<UserEditViewModel>> => {
  return executeApiRequest<UserEditViewModel>(async () => {
    return await UserServiceClient(config).get(
      userApiPaths.editUserDetails(userId)
    );
  });
};

export const updateUser = async (
  userId: string,
  body: UserEditModel,
  config?: DataRequestConfig
): Promise<ApiResponseModel<unknown>> => {
  return executeApiRequest<unknown>(async () => {
    return await UserServiceClient(config).put(
      userApiPaths.updateUser(userId),
      body
    );
  });
};

export const activateUser = async (
  userId: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<unknown>> => {
  return executeApiRequest<unknown>(async () => {
    return await UserServiceClient(config).put(
      userApiPaths.activateUser(userId)
    );
  });
};

export const deactivateUser = async (
  userId: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<unknown>> => {
  return executeApiRequest<unknown>(async () => {
    return await UserServiceClient(config).put(
      userApiPaths.deactivateUser(userId)
    );
  });
};

export const updateCurrentDistrict = async (
  districtId: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<CurrentUserDetailViewModel>> => {
  return executeApiRequest<CurrentUserDetailViewModel>(async () => {
    return await UserServiceClient(config).put(
      userApiPaths.updateCurrentDistrict(districtId)
    );
  });
};
