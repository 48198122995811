/* eslint-disable max-len */
import { AxiosError } from 'axios';
import { Base64 } from 'js-base64';
import { errorLoaded } from '../redux/slices/errorSlice';
import { ApiErrorModel } from '../types/ApiErrorModel';
import { ApiResponseModel } from '../types/ApiResponseModel';
import { AuthTokenRefreshPayload } from '../types/AuthTokenRefreshPayload';
import { ReportListModel } from '../types/ReportListModel';
import { ReportTokenRefreshPayload } from '../types/ReportTokenRefreshPayload';
import { ThunkAsyncBackbone } from '../types/ThunkAsyncBackbone';
import { CurrentUserDetailViewModel } from '../types/UserDetailModel';
import {
  clearItemInStorage,
  getItemInStorage,
  setItemInStorage,
} from './storage.service';

export enum SESSION_STORAGE_KEY {
  APP_TOKEN_DATA = 'app_token_data',
  REPORT_TOKEN_DATA = 'report_token_data',
  REPORT_LIST_DATA = 'report_list',
  LOGIN_ROUTE = 'login_route',
  USER_DETAILS = 'user_details',
}

const getItemDecodeAndParse = <T>(key: string): T => {
  let details = {};
  const value = getItemInStorage(key);
  if (value) {
    details = JSON.parse(Base64.decode(value));
  }
  return details as T;
};

export const getAuthToken = (): string | undefined => {
  const details = getItemDecodeAndParse<AuthTokenRefreshPayload>(
    SESSION_STORAGE_KEY.APP_TOKEN_DATA
  );
  return details?.token || undefined;
};

export const getAuthTokenRequestDateAsMSInt = (): number | undefined => {
  const details = getItemDecodeAndParse<AuthTokenRefreshPayload>(
    SESSION_STORAGE_KEY.APP_TOKEN_DATA
  );
  return details?.requestedDateTimeInMS || undefined;
};

export const getAuthTokenExpirationSeconds = (): number | undefined => {
  const details = getItemDecodeAndParse<AuthTokenRefreshPayload>(
    SESSION_STORAGE_KEY.APP_TOKEN_DATA
  );
  return details?.expiration || undefined;
};

export const getAuthTokenRefreshCode = (): string | undefined => {
  const details = getItemDecodeAndParse<AuthTokenRefreshPayload>(
    SESSION_STORAGE_KEY.APP_TOKEN_DATA
  );
  return details?.refresh_code || undefined;
};

export const getReportToken = (): string | undefined => {
  const model = getItemDecodeAndParse<ReportTokenRefreshPayload>(
    SESSION_STORAGE_KEY.REPORT_TOKEN_DATA
  );
  return model?.token || undefined;
};

export const getReportTokenRequestDateAsMSInt = (): number | undefined => {
  const details = getItemDecodeAndParse<ReportTokenRefreshPayload>(
    SESSION_STORAGE_KEY.REPORT_TOKEN_DATA
  );
  return details?.requestedDateTimeInMS || undefined;
};

export const getReportTokenExpirationMinutes = (): number | undefined => {
  const details = getItemDecodeAndParse<ReportTokenRefreshPayload>(
    SESSION_STORAGE_KEY.REPORT_TOKEN_DATA
  );
  return details?.expiration || undefined;
};

export const getReportList = (): ReportListModel | undefined => {
  const model = getItemDecodeAndParse<ReportListModel>(
    SESSION_STORAGE_KEY.REPORT_LIST_DATA
  );
  return Object.keys(model).length === 0 ? undefined : model;
};

export const getLoginRoute = (): string | undefined => {
  return getItemInStorage(SESSION_STORAGE_KEY.LOGIN_ROUTE) || undefined;
};

export const setTokenResponse = (response: AuthTokenRefreshPayload): void => {
  const value = Base64.encode(JSON.stringify(response));
  setItemInStorage(SESSION_STORAGE_KEY.APP_TOKEN_DATA, value);
};

export const setReportTokenResponse = (
  response: ReportTokenRefreshPayload
): void => {
  const value = Base64.encode(JSON.stringify(response));
  setItemInStorage(SESSION_STORAGE_KEY.REPORT_TOKEN_DATA, value);
};

export const setReportListResponse = (response: ReportListModel): void => {
  const value = Base64.encode(JSON.stringify(response));
  setItemInStorage(SESSION_STORAGE_KEY.REPORT_LIST_DATA, value);
};

export const setSessionLoginRoute = (route: string): void => {
  setItemInStorage(SESSION_STORAGE_KEY.LOGIN_ROUTE, route);
};

export const clearReportTokenAndList = (): void => {
  const storageItemsToClear = [
    SESSION_STORAGE_KEY.REPORT_TOKEN_DATA,
    SESSION_STORAGE_KEY.REPORT_LIST_DATA,
  ];
  storageItemsToClear.forEach((item) => clearItemInStorage(item));
};

export const clearStorageForLogin = (): void => {
  const storageItemsToClear = [
    SESSION_STORAGE_KEY.REPORT_TOKEN_DATA,
    SESSION_STORAGE_KEY.REPORT_LIST_DATA,
    SESSION_STORAGE_KEY.USER_DETAILS,
    SESSION_STORAGE_KEY.APP_TOKEN_DATA,
  ];
  storageItemsToClear.forEach((item) => clearItemInStorage(item));
};

export const clearLoginRoute = (): void => {
  clearItemInStorage(SESSION_STORAGE_KEY.LOGIN_ROUTE);
};

export const tokenIsPresent = (): boolean => {
  const token = getAuthToken();
  return token && token.length > 0 ? true : false;
};

export const getCurrentUserAccess = ():
  | CurrentUserDetailViewModel
  | undefined => {
  let details = undefined;
  const value = getItemInStorage(SESSION_STORAGE_KEY.USER_DETAILS);
  if (value) {
    details = JSON.parse(Base64.decode(value));
  }
  return details;
};

export const getStoredUserName = (): string | undefined => {
  const details = getCurrentUserAccess();
  return details?.name;
};

export const setCurrentUserAccess = (
  details: CurrentUserDetailViewModel
): void => {
  const value = Base64.encode(JSON.stringify(details));
  return setItemInStorage(SESSION_STORAGE_KEY.USER_DETAILS, value);
};

export const isValidCode = (statusCode: number): boolean => {
  return statusCode < 400;
};

export const errorIsGenericException = (
  error: Record<string, unknown>
): boolean => {
  if (error.traceId) {
    return true;
  }
  return false;
};

const createErrorModel = (
  status: number,
  detail: string,
  dataObj?: unknown
): ApiErrorModel => ({
  type: 'Error',
  status,
  title: 'Error Occured',
  detail,
  dataObj,
});

export const executeApiRequest = async <T>(
  apiFunc: Function
): Promise<ApiResponseModel<T>> => {
  interface ErrorModel {
    status: number;
    error: ApiErrorModel;
    aborted: boolean;
  }

  const constructErrorDetails = (err: AxiosError): ErrorModel => {
    if (err.code === 'ERR_CANCELED') {
      return {
        aborted: true,
        status: 500,
        error: createErrorModel(500, 'Manually Aborted'),
      };
    } else {
      if (
        err.code === 'ECONNABORTED' &&
        err.message.indexOf('timeout') !== -1
      ) {
        return {
          aborted: false,
          status: 408,
          error: createErrorModel(408, 'Timeout Exceeded'),
        };
      } else {
        return {
          aborted: false,
          status: 500,
          error: createErrorModel(500, 'Network Error'),
        };
      }
    }
  };

  let result;
  let status;
  let aborted = false;
  let data = {} as T;
  let error: ApiErrorModel = {} as ApiErrorModel;

  try {
    result = await apiFunc();
    status = result.status;

    if (isValidCode(status)) {
      data = result.data;
    } else {
      if (errorIsGenericException(result.data)) {
        error = result.data;
      } else {
        error = createErrorModel(result.status, '', result.data);
      }
    }
  } catch (e) {
    const err = e as unknown as AxiosError;
    // Mark program controlled aborted requests
    const errorDetails = constructErrorDetails(err);
    error = errorDetails.error;
    status = errorDetails.status;
    aborted = errorDetails.aborted;
  } finally {
    return {
      data,
      error,
      status,
      aborted,
    };
  }
};

export const thunkExecuteAndReturnResultOrShowError = async <T>(
  thunkAPI: ThunkAsyncBackbone,
  apiFunc: Promise<ApiResponseModel<T>>
): Promise<T> => {
  const response = await apiFunc;
  if (!isValidCode(response.status)) {
    if (!response.aborted) {
      thunkAPI.dispatch(errorLoaded(response.error));
    }
  }
  return response.data;
};

// export const thunkExecuteAndReturnResultOrShowReportError = async <T>(
//   thunkAPI: ThunkAsyncBackbone,
//   apiFunc: Promise<MultiThunkCall<T>>
// ): Promise<T | undefined> => {
//   const response = await apiFunc;
//   if (response.error) {
//     //if (!response.aborted) {
//     thunkAPI.dispatch(setReportError(response.error));
//     //}
//   }
//   return response.data;
// };

export const abortPromiseOnUnmount = (payloadAction?: unknown): void => {
  type SimpleActionPayload = {
    abort?: Function;
  };

  const promise = payloadAction as SimpleActionPayload;
  promise && promise.abort && promise.abort();
};
