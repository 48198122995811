/* eslint-disable max-len */
import { ReactElement } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { selectNonPublicStudentFormViewModel } from '../../../redux/slices/nonPublicStudentSlice';
import { NonPublicStudentFormViewModel } from '../../../types/NonPublicStudentFormViewModel';
import { PanelType } from '../../../types/PanelType';
import { FormRadioListOrientation } from '../../../types/propTypes/FormRadioListPropTypes';
import { NonPublicStudentEntryValidationSchema } from '../../../types/Validation/NonPublicStudentEntryValidationSchema';
import FormDropdown from '../../FormInputs/FormDropdown/FormDropdown';
import FormRadioList from '../../FormInputs/FormRadioList/FormRadioList';
import Panel from '../../Panel/Panel';
import './NPSE_DemographicPanel.css';

const NPSE_DemographicPanel = (): ReactElement => {
  const formViewModel: NonPublicStudentFormViewModel = useAppSelector(
    selectNonPublicStudentFormViewModel
  );

  return (
    <Panel panelType={PanelType.INFO} heading="Student Demographics">
      <div className="field-row field-row-demographic">
        <FormDropdown
          displayName="Gender"
          field="gender"
          options={formViewModel.genderOptions}
          validationSchema={NonPublicStudentEntryValidationSchema}
        />
        <FormDropdown
          displayName="Ethnicity"
          field="ethnicity"
          options={formViewModel.ethnicityOptions}
          validationSchema={NonPublicStudentEntryValidationSchema}
        />
        <FormDropdown
          displayName="Race"
          field="race"
          options={formViewModel.raceOptions}
          validationSchema={NonPublicStudentEntryValidationSchema}
        />
        <FormRadioList
          displayName="Special Education"
          field="specialEducation"
          options={formViewModel.specialEducationOptions}
          optionOrientation={FormRadioListOrientation.Horizontal}
          validationSchema={NonPublicStudentEntryValidationSchema}
        />
        <FormRadioList
          displayName="English Learner"
          field="englishLearner"
          options={formViewModel.englishLearnerOptions}
          optionOrientation={FormRadioListOrientation.Horizontal}
          validationSchema={NonPublicStudentEntryValidationSchema}
        />
      </div>
    </Panel>
  );
};

export default NPSE_DemographicPanel;
