/* eslint-disable max-len */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AccreditationDashboardModel } from 'src/types/AccreditationDashboardModel';
import { AccreditationEdit } from 'src/types/AccreditationEditList';
import {
  getAccreditationEditsByCategory,
  getAccreditationEditsCount,
} from '../../services/accreditation.service';
import { thunkExecuteAndReturnResultOrShowError } from '../../services/base.service';

export const fetchAccreditationEditsCount = createAsyncThunk(
  'input/fetchAccreditationEditsCount',
  async (_, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<
      AccreditationDashboardModel[]
    >(thunkAPI, getAccreditationEditsCount({ signal: thunkAPI.signal }));
  }
);

export const fetchAccreditationEditItemsForCategory = createAsyncThunk(
  'input/fetchAccreditationEditItemsForCategory',
  async (category: string, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<AccreditationEdit>(
      thunkAPI,
      getAccreditationEditsByCategory(category, { signal: thunkAPI.signal })
    );
  }
);
