import { ApiResponseModel } from '../types/ApiResponseModel';
import { DataQualityDashboardModel } from '../types/DataQualityDashboardModel';
import { DataQualityErrorList } from '../types/DataQualityErrorList';
import { DataRequestConfig } from '../types/DataRequestConfig';
import { getApiBaseUrl } from './app.service';
import { executeApiRequest, getAuthToken } from './base.service';
import { getHttpClient, HttpClient } from './httpClient';

export const dataQualityApiPaths = {
  dataQuality: 'dataquality',
  getDataQualityErrorsByDomain: (domain: string): string =>
    `/dataquality/error-list?domain=${domain}`,
};

export const DataQualityServiceClient = (
  config?: DataRequestConfig
): HttpClient =>
  getHttpClient(getApiBaseUrl(), getAuthToken() || '', config?.signal);

export const getDataQualityDashboard = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<DataQualityDashboardModel[]>> => {
  return executeApiRequest<DataQualityDashboardModel[]>(async () => {
    return await DataQualityServiceClient(config).get(
      dataQualityApiPaths.dataQuality
    );
  });
};

export const getDataQualityErrorsByCategory = async (
  domain: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<DataQualityErrorList>> => {
  return executeApiRequest<DataQualityErrorList>(async () => {
    return await DataQualityServiceClient(config).get(
      dataQualityApiPaths.getDataQualityErrorsByDomain(domain)
    );
  });
};
