import { createSlice } from '@reduxjs/toolkit';
import { DataQualityDashboardModel } from '../../types/DataQualityDashboardModel';
import { DataQualityErrorList } from '../../types/DataQualityErrorList';
import { isEmptyObject } from '../../utilities/helperUtilities';
import type { RootState } from '../store';
import {
  fetchDataQualityData,
  fetchDataQualityItemsForCategory,
} from '../thunks/dataQualityThunks';

interface DataQualityState {
  loading: boolean;
  dashboardItems: DataQualityDashboardModel[];
  categoryErrors: DataQualityErrorList;
}

export const defaultDataQualityErrorList: DataQualityErrorList = {
  domain: '',
  tabs: [],
};

export const initialState: DataQualityState = {
  loading: false,
  dashboardItems: [],
  categoryErrors: defaultDataQualityErrorList,
};

export const dataQualitySlice = createSlice({
  name: 'dataQuality',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDataQualityData.pending, (state) => {
      state.dashboardItems = [];
      state.loading = true;
    });
    builder.addCase(fetchDataQualityData.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        state.dashboardItems = action.payload;
      }
      state.loading = false;
    });
    builder.addCase(fetchDataQualityData.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loading = false;
      }
    });
    builder.addCase(fetchDataQualityItemsForCategory.pending, (state) => {
      state.categoryErrors = defaultDataQualityErrorList;
      state.loading = true;
    });
    builder.addCase(
      fetchDataQualityItemsForCategory.fulfilled,
      (state, action) => {
        if (!isEmptyObject(action.payload)) {
          state.categoryErrors = action.payload;
        }
        state.loading = false;
      }
    );
    builder.addCase(
      fetchDataQualityItemsForCategory.rejected,
      (state, action) => {
        if (!action.meta.aborted) {
          state.loading = false;
        }
      }
    );
  },
});

export const selectDataQualityLoading = (state: RootState): boolean =>
  state.dataQuality.loading;
export const selectDashboardItems = (
  state: RootState
): DataQualityDashboardModel[] => state.dataQuality.dashboardItems;
export const selectCategoryErrors = (state: RootState): DataQualityErrorList =>
  state.dataQuality.categoryErrors;

export default dataQualitySlice.reducer;
