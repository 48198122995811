/* eslint-disable max-len */
import { ErrorMessage, Field, useField, useFormikContext } from 'formik';
import { ReactElement } from 'react';
import { MultiValue, Options, SingleValue } from 'react-select';
import {
  FormDropdownPropTypes,
  FormDropdownSelectionValue,
  FormDropdownSelectOption,
} from '../../../types/propTypes/FormDropdownPropTypes';
import { ReactSelectOption } from '../../../types/ReactSelectOption';
import { applyClassesForInput } from '../../../utilities/formUtilities';
import StyledSingleDropdown from '../../StyledSingleDropdown/StyledSingleDropdown';
import FormInputLabel from '../FormInputLabel/FormInputLabel';
import InlineError from '../InlineError/InlineError';
import './FormDropdown.css';

const FormDropdown = (props: FormDropdownPropTypes): ReactElement => {
  const {
    field: fieldName,
    options,
    isMulti,
    displayName,
    disabled,
    classConfig,
    onChangeEvent,
    validationSchema,
  } = props;

  const [field, , { setValue, setTouched }] = useField(fieldName);
  const { validateForm, resetForm, setFieldValue } = useFormikContext();

  const reactSelectOptions: Options<ReactSelectOption> = options.map((x) => ({
    value: x.value,
    label: x.text,
    isDisabled: x.isDisabled ?? false,
  }));

  const handleBlur = (): void => {
    setTouched(true);
    validateForm();
  };

  const handleValueChange = async (
    selected: FormDropdownSelectOption
  ): Promise<void> => {
    const getDropdownValueOrValues = (): FormDropdownSelectionValue => {
      if (!isMulti) {
        return (selected as SingleValue<ReactSelectOption>)?.value;
      } else {
        return (selected as MultiValue<ReactSelectOption>).map((x) => x.value);
      }
    };

    const getDropdownOptionTextOrList = (): FormDropdownSelectionValue => {
      if (!isMulti) {
        return (selected as SingleValue<ReactSelectOption>)?.label;
      } else {
        return (selected as MultiValue<ReactSelectOption>).map((x) => x.label);
      }
    };

    setTouched(true);
    const newValue = getDropdownValueOrValues();
    const newText = getDropdownOptionTextOrList();

    if (onChangeEvent) {
      await onChangeEvent({
        value: newValue,
        optionText: newText,
        field: fieldName,
        resetHook: resetForm,
        setValueHook: setFieldValue,
      });
    }

    setValue(newValue, true);
  };

  const getValueFromField = (): FormDropdownSelectOption => {
    if (!isMulti) {
      return reactSelectOptions.find((rso) => rso.value == field.value) ?? null;
    } else {
      return reactSelectOptions.filter((rso) =>
        (field.value as string[]).some((fv) => fv === rso.value)
      );
    }
  };

  const dropdownTypeName = isMulti ? 'multi' : 'single';

  return (
    <div
      className={`field-container ${classConfig?.containerClasses || ''} ${
        disabled ? 'disabled' : ''
      }`}
      data-testid={`form-dropdown-${dropdownTypeName}-${fieldName}`}
    >
      {displayName && (
        <FormInputLabel
          field={fieldName}
          displayName={displayName}
          validationSchema={validationSchema}
          classes={classConfig?.labelClasses || ''}
        />
      )}
      <Field
        labelText={displayName}
        showLabel={false}
        component={StyledSingleDropdown}
        isMulti={isMulti}
        fieldName={fieldName}
        name={fieldName}
        inputId={fieldName}
        inputClasses={classConfig?.inputClasses || ''}
        onChange={handleValueChange}
        onBlur={handleBlur}
        value={getValueFromField()}
        isDisabled={disabled}
        controlClass={applyClassesForInput(fieldName, `control`)}
        // Used to pass any native select props through
        {...props}
      />
      <ErrorMessage name={fieldName} component={InlineError}></ErrorMessage>
    </div>
  );
};

export default FormDropdown;
