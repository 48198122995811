import { get } from 'lodash';
import { SchoolModel } from '../types/DistrictModel';
import {
  FormSchoolBasedPermission,
  UserEditFormModel,
  UserEditModel,
} from '../types/UserEditModel';
import {
  SchoolBasedPermission,
  SchoolBasedPermissionMetadata,
  UserEditViewModel,
} from '../types/UserEditViewModel';
import { UserType } from '../types/UserType';

export const mapToFormModel = (model: UserEditViewModel): UserEditFormModel => {
  const convertFormSchoolPermissionListToObject = (
    domain: SchoolBasedPermissionMetadata
  ): FormSchoolBasedPermission => {
    const schools: SchoolModel[] = get(
      model,
      `user.schoolBasedPermissions[${domain.name}].schools`,
      []
    );
    return {
      schoolIds: schools.map((schl) => schl.schoolId),
      canCertifyDistrict: get(
        model,
        `user.schoolBasedPermissions[${domain.name}].canCertifyDistrict`,
        false
      ),
      canCertifySchools: get(
        model,
        `user.schoolBasedPermissions[${domain.name}].canCertifySchools`,
        false
      ),
      msisIdRequestAccess: get(
        model,
        `user.schoolBasedPermissions[${domain.name}].msisIdRequestAccess`,
        false
      ),
      studentOwnershipAccess: get(
        model,
        `user.schoolBasedPermissions[${domain.name}].studentOwnershipAccess`,
        false
      ),
    };
  };

  const buildPermissionFormList = (): Record<
    string,
    FormSchoolBasedPermission
  > => {
    const obj: Record<string, FormSchoolBasedPermission> = {};
    model.assignableSchoolBasedPermissions.map((role) => {
      obj[role.name] = convertFormSchoolPermissionListToObject(role);
    });
    return obj;
  };

  const permissions =
    model.user.isAdmin && model.user.userType == UserType.State
      ? model.assignableRoles
      : model.user.assignedRoles;

  return {
    permissions,
    schoolBasedPermissions: buildPermissionFormList(),
  };
};

export const mapFormToEditModel = (
  model: UserEditFormModel,
  schoolRoles: string[],
  schools: SchoolModel[]
): UserEditModel => {
  const convertSchoolIdListToPermission = (
    domain: string
  ): SchoolBasedPermission => {
    const schoolIds: string[] = get(
      model,
      `schoolBasedPermissions[${domain}].schoolIds`,
      []
    );
    return {
      schools: schools.filter((schl) => schoolIds.includes(schl.schoolId)),
      canCertifyDistrict: get(
        model,
        `schoolBasedPermissions[${domain}].canCertifyDistrict`,
        false
      ),
      canCertifySchools: get(
        model,
        `schoolBasedPermissions[${domain}].canCertifySchools`,
        false
      ),
      msisIdRequestAccess: get(
        model,
        `schoolBasedPermissions[${domain}].msisIdRequestAccess`,
        false
      ),
      studentOwnershipAccess: get(
        model,
        `schoolBasedPermissions[${domain}].studentOwnershipAccess`,
        false
      ),
    };
  };

  const buildPermissionSaveList = (): Record<string, SchoolBasedPermission> => {
    const obj: Record<string, SchoolBasedPermission> = {};
    schoolRoles.map((role) => {
      if (convertSchoolIdListToPermission(role).schools.length > 0) {
        obj[role] = convertSchoolIdListToPermission(role);
      }
    });
    return obj;
  };

  return {
    selectedPermissions: model.permissions,
    schoolBasedPermissions: buildPermissionSaveList(),
  };
};
