/* eslint-disable max-len */
import { useFormikContext } from 'formik';
import { isEqual } from 'lodash';
import { ReactElement } from 'react';
import ActionButton from '../../../components/ActionButton/ActionButton';
import Panel from '../../../components/Panel/Panel';
import { mapToFormModel } from '../../../mappers/UserEditMapper';
import { ButtonSize } from '../../../types/ButtonSize';
import { PanelType } from '../../../types/PanelType';
import { SchoolBasedPermissionsPropTypes } from '../../../types/propTypes/SchoolBasedPermissionsPropTypes';
import { UserEditFormModel } from '../../../types/UserEditModel';
import {
  SchoolBasedPermission,
  SchoolBasedPermissionMetadata,
  UserEditViewModel,
} from '../../../types/UserEditViewModel';
import { isStateUser } from '../../../utilities/userUtilities';
import SchoolBasedPermissionSelection from '../SchoolBasedPermissionSelection/SchoolBasedPermissionSelection';

const SchoolBasedPermissions = ({
  model,
  schoolOptions,
  clearParentError,
}: SchoolBasedPermissionsPropTypes): ReactElement => {
  const { setValues, values } = useFormikContext();

  const selectAllSchoolAssignments = (): UserEditViewModel => {
    const existing = values as UserEditFormModel;
    const allSchools = model.assignableSchools;
    const updatedDomainAssignments: Record<string, SchoolBasedPermission> = {};
    model.assignableSchoolBasedPermissions.forEach(
      (domain: SchoolBasedPermissionMetadata) => {
        updatedDomainAssignments[domain.name] = {
          schools: allSchools,
          canCertifyDistrict:
            existing.schoolBasedPermissions[domain.name].canCertifyDistrict,
          canCertifySchools:
            existing.schoolBasedPermissions[domain.name].canCertifySchools,
          msisIdRequestAccess:
            existing.schoolBasedPermissions[domain.name].msisIdRequestAccess,
          studentOwnershipAccess:
            existing.schoolBasedPermissions[domain.name].studentOwnershipAccess,
        };
      }
    );

    return {
      ...model,
      user: {
        ...model.user,
        schoolBasedPermissions: updatedDomainAssignments,
      },
    };
  };

  const handleSelectAll = (setFormValues: Function): void => {
    clearParentError();
    const tempDetails = selectAllSchoolAssignments();
    setFormValues(mapToFormModel(tempDetails));
  };

  const selectNoneSchoolAssignments = (): UserEditViewModel => {
    const updatedDomainAssignments: Record<string, SchoolBasedPermission> = {};
    model.assignableSchoolBasedPermissions.forEach(
      (domain: SchoolBasedPermissionMetadata) => {
        updatedDomainAssignments[domain.name] = {
          schools: [],
          canCertifyDistrict: false,
          canCertifySchools: false,
          msisIdRequestAccess: false,
          studentOwnershipAccess: false,
        };
      }
    );

    return {
      ...model,
      user: {
        ...model.user,
        schoolBasedPermissions: updatedDomainAssignments,
      },
    };
  };

  const handleSelectNone = (setFormValues: Function): void => {
    clearParentError();
    const tempDetails = selectNoneSchoolAssignments();
    setFormValues(mapToFormModel(tempDetails));
  };

  const disableSelectAll = (inputValues: UserEditFormModel): boolean => {
    const allSchoolsSelected = (): boolean => {
      const allSchoolIds = model.assignableSchools.map((scl) => scl.schoolId);
      const selectedSchoolIdSets = Object.values(
        inputValues.schoolBasedPermissions
      );
      return selectedSchoolIdSets.every((idSet) =>
        isEqual(idSet.schoolIds, allSchoolIds)
      );
    };

    return allSchoolsSelected();
  };

  const disableUnselectAll = (inputValues: UserEditFormModel): boolean => {
    const noSchoolsAssigned = (): boolean =>
      Object.values(inputValues.schoolBasedPermissions).every(
        (record) => record.schoolIds.length === 0
      );

    return noSchoolsAssigned();
  };

  return !isStateUser(model.user.userType) ? (
    <div className="multi-selection-section" data-testid="multi-select-section">
      <Panel panelType={PanelType.INFO} heading={'School Based Permissions'}>
        <div className="button-group">
          <ActionButton
            buttonType="button"
            classes="button no-wrap-text select-all-button"
            onClick={() => handleSelectAll(setValues)}
            dataTestId="edit-select-all"
            disabled={disableSelectAll(values as UserEditFormModel)}
            cypressDataId="edit-select-all"
            tooltipText="Select All"
            size={ButtonSize.SMALL}
          >
            <span>Select All</span>
          </ActionButton>
          <ActionButton
            buttonType="button"
            classes="button no-wrap-text select-none-button"
            onClick={() => handleSelectNone(setValues)}
            dataTestId="edit-select-none"
            disabled={disableUnselectAll(values as UserEditFormModel)}
            cypressDataId="edit-select-none"
            tooltipText="Select None"
            size={ButtonSize.SMALL}
          >
            <span>Select None</span>
          </ActionButton>
        </div>
        {model.assignableSchoolBasedPermissions &&
          model.assignableSchoolBasedPermissions.map((domain) => (
            <SchoolBasedPermissionSelection
              key={`permission-${domain.name}-selection`}
              domain={domain}
              schoolOptions={schoolOptions}
            />
          ))}
      </Panel>
    </div>
  ) : (
    <></>
  );
};

export default SchoolBasedPermissions;
