import { createAsyncThunk } from '@reduxjs/toolkit';
import { thunkExecuteAndReturnResultOrShowError } from '../../services/base.service';
import {
  getDataQualityDashboard,
  getDataQualityErrorsByCategory,
} from '../../services/dataQuality.service';
import { DataQualityDashboardModel } from '../../types/DataQualityDashboardModel';
import { DataQualityErrorList } from '../../types/DataQualityErrorList';

export const fetchDataQualityData = createAsyncThunk(
  'dataQuality/fetchDataQualityData',
  async (_, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<
      DataQualityDashboardModel[]
    >(thunkAPI, getDataQualityDashboard({ signal: thunkAPI.signal }));
  }
);

export const fetchDataQualityItemsForCategory = createAsyncThunk(
  'dataQuality/fetchDataQualityItemsForCategory',
  async (category: string, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<DataQualityErrorList>(
      thunkAPI,
      getDataQualityErrorsByCategory(category, { signal: thunkAPI.signal })
    );
  }
);
