/* eslint-disable max-len */
/* istanbul ignore file */
import { configureStore } from '@reduxjs/toolkit';
import { accreditationSlice } from './slices/accreditationSlice';
import { appSlice } from './slices/appSlice';
import { certificationSlice } from './slices/certificationSlice';
import { charterSchoolAssignmentSlice } from './slices/charterSchoolAssignmentSlice';
import { charterSchoolAuthorizingOrganizationSlice } from './slices/charterSchoolAuthorizingOrganizationSlice';
import { charterSchoolManagementOrganizationSlice } from './slices/charterSchoolManagementOrganizationSlice';
import { courseCodeSlice } from './slices/courseCodeSlice';
import { dashboardSlice } from './slices/dashboardSlice';
import { dataQualitySlice } from './slices/dataQualitySlice';
import { errorSlice } from './slices/errorSlice';
import { fileSlice } from './slices/fileSlice';
import { leaManagementSlice } from './slices/leaManagementSlice';
import { leaSharingSlice } from './slices/leaSharingSlice';
import { loginSlice } from './slices/loginSlice';
import { nonPublicStudentSlice } from './slices/nonPublicStudentSlice';
import { notificationSlice } from './slices/notificationSlice';
import { reportSlice } from './slices/reportSlice';
import { schoolManagementSlice } from './slices/schoolManagementSlice';
import { seaOrganizationSlice } from './slices/seaOrganizationSlice';
import { studentSlice } from './slices/studentSlice';
import { userSlice } from './slices/userSlice';

export const store = configureStore({
  reducer: {
    app: appSlice.reducer,
    file: fileSlice.reducer,
    dataQuality: dataQualitySlice.reducer,
    accreditation: accreditationSlice.reducer,
    error: errorSlice.reducer,
    notification: notificationSlice.reducer,
    user: userSlice.reducer,
    login: loginSlice.reducer,
    student: studentSlice.reducer,
    report: reportSlice.reducer,
    dashboard: dashboardSlice.reducer,
    courseCode: courseCodeSlice.reducer,
    charterSchoolAuthorizingOrganization:
      charterSchoolAuthorizingOrganizationSlice.reducer,
    charterSchoolManagementOrganization:
      charterSchoolManagementOrganizationSlice.reducer,
    charterSchoolAssignment: charterSchoolAssignmentSlice.reducer,
    seaOrganization: seaOrganizationSlice.reducer,
    leaManagement: leaManagementSlice.reducer,
    leaSharing: leaSharingSlice.reducer,
    certification: certificationSlice.reducer,
    schoolManagement: schoolManagementSlice.reducer,
    nonPublicStudent: nonPublicStudentSlice.reducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type
export type AppDispatch = typeof store.dispatch;
