/* eslint-disable max-len */
import { useFormikContext } from 'formik';
import { ReactElement } from 'react';
import FormCheckbox from '../../../components/FormInputs/FormCheckbox/FormCheckbox';
import FormDropdown from '../../../components/FormInputs/FormDropdown/FormDropdown';
import FormInputLabel from '../../../components/FormInputs/FormInputLabel/FormInputLabel';
import { FormDropdownChangeEventConfig } from '../../../types/propTypes/FormDropdownPropTypes';
import { SchoolBasedPermissionSelectionPropTypes } from '../../../types/propTypes/SchoolBasedPermissionSelectionPropTypes';
import { UserEditFormModel } from '../../../types/UserEditModel';
import { getRoleDisplayText } from '../../../utilities/userUtilities';
import './SchoolBasedPermissionSelection.css';

const SchoolBasedPermissionSelection = ({
  domain,
  schoolOptions,
}: SchoolBasedPermissionSelectionPropTypes): ReactElement => {
  const { values } = useFormikContext();

  const isDisabled = (): boolean => {
    return (
      (values as UserEditFormModel).schoolBasedPermissions[domain.name]
        .schoolIds.length === 0
    );
  };

  // If dropdown is cleared out, then unselect certification checkboxes
  const handleDropdownChanges = async (
    props: FormDropdownChangeEventConfig
  ): Promise<void> => {
    if ((props.value as []).length === 0 && props.setValueHook) {
      props.setValueHook(
        `schoolBasedPermissions.${domain.name}.canCertifyDistrict`,
        false
      );
      props.setValueHook(
        `schoolBasedPermissions.${domain.name}.canCertifySchools`,
        false
      );
      props.setValueHook(
        `schoolBasedPermissions.${domain.name}.msisIdRequestAccess`,
        false
      );
      props.setValueHook(
        `schoolBasedPermissions.${domain.name}.studentOwnershipAccess`,
        false
      );
    }
  };

  return (
    <div
      className="multi-school-input"
      key={domain.name}
      data-testid={`input-${domain.name}`}
    >
      <div className="label-with-checkboxes">
        <FormInputLabel
          field={`schoolBasedPermissions.${domain.name}`}
          displayName={getRoleDisplayText(domain.name)}
          classes="permission-name"
        />
        {domain.canCertifyDistrict && (
          <FormCheckbox
            displayName="Can Certify District"
            field={`schoolBasedPermissions.${domain.name}.canCertifyDistrict`}
            disabled={isDisabled()}
            disableLabelOnCheckboxDisabled={true}
          ></FormCheckbox>
        )}
        {domain.canCertifySchools && (
          <FormCheckbox
            displayName="Can Certify School(s)"
            field={`schoolBasedPermissions.${domain.name}.canCertifySchools`}
            disabled={isDisabled()}
            disableLabelOnCheckboxDisabled={true}
          ></FormCheckbox>
        )}
        {domain.msisIdRequestAccess && (
          <FormCheckbox
            displayName="MSIS ID Request"
            field={`schoolBasedPermissions.${domain.name}.msisIdRequestAccess`}
            disabled={isDisabled()}
            disableLabelOnCheckboxDisabled={true}
          ></FormCheckbox>
        )}
        {domain.studentOwnershipAccess && (
          <FormCheckbox
            displayName="Student Ownership"
            field={`schoolBasedPermissions.${domain.name}.studentOwnershipAccess`}
            disabled={isDisabled()}
            disableLabelOnCheckboxDisabled={true}
          ></FormCheckbox>
        )}
      </div>
      <FormDropdown
        field={`schoolBasedPermissions.${domain.name}.schoolIds`}
        options={schoolOptions}
        isMulti={true}
        onChangeEvent={handleDropdownChanges}
      />
    </div>
  );
};

export default SchoolBasedPermissionSelection;
