/* eslint-disable max-len */
import { ReactElement, useEffect } from 'react';
import { AccreditationDashboardModel } from 'src/types/AccreditationDashboardModel';
import Bubble from '../../components/Bubble/Bubble';
import NavigationPage from '../../components/NavigationPage/NavigationPage';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectAccreditationDashboardItems } from '../../redux/slices/accreditationSlice';
import { fetchAccreditationEditsCount } from '../../redux/thunks/accreditationThunks';
import { abortPromiseOnUnmount } from '../../services/base.service';
import { appRoutePaths } from '../../services/route.service';
import './Accreditation.css';

const Accreditation = (): ReactElement => {
  const dashboardItems: AccreditationDashboardModel[] = useAppSelector(
    selectAccreditationDashboardItems
  );

  const isLoading = false;
  const dispatch = useAppDispatch();

  useEffect(() => {
    const promise: unknown = dispatch(fetchAccreditationEditsCount());
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch]);

  return (
    <NavigationPage
      pageClass="accreditation"
      heading={`Accreditation Categories`}
      isLoading={isLoading}
      loadingDataId="accreditation"
      loadingText="Getting your items"
    >
      {dashboardItems.length
        ? dashboardItems.map((item, index) => (
            <Bubble
              key={index}
              count={item.editCount}
              title={item.domain}
              classConfig="temp"
              bubbleText="Edits"
              navigateTo={appRoutePaths.AccreditationEdits(item.domain)}
              visibilityOverrideRoute={appRoutePaths.Accreditation}
            />
          ))
        : null}
    </NavigationPage>
  );
};

export default Accreditation;
