/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import { Form, Formik } from 'formik';
import { noop } from 'lodash';
import { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ActionButton from '../../components/ActionButton/ActionButton';
import FormCheckboxList from '../../components/FormInputs/FormCheckboxList/FormCheckboxList';
import LabelAndValue from '../../components/LabelAndValue/LabelAndValue';
import NavigationPage from '../../components/NavigationPage/NavigationPage';
import Panel from '../../components/Panel/Panel';
import {
  mapFormToEditModel,
  mapToFormModel,
} from '../../mappers/UserEditMapper';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectEditUserDetails,
  selectUserLoading,
} from '../../redux/slices/userSlice';
import { fetchEditUserDetailsById } from '../../redux/thunks/userThunks';
import {
  abortPromiseOnUnmount,
  isValidCode,
} from '../../services/base.service';
import { appRoutePaths } from '../../services/route.service';
import { updateUser } from '../../services/user.service';
import { GLOBAL_DEBUG_MODE } from '../../testing/debugFunctions';
import { ApiErrorModel } from '../../types/ApiErrorModel';
import { PanelType } from '../../types/PanelType';
import { SelectOption } from '../../types/SelectOption';
import { UserEditFormModel } from '../../types/UserEditModel';
import { UserEditViewModel } from '../../types/UserEditViewModel';
import { UserType } from '../../types/UserType';
import { getRoleDisplayText } from '../../utilities/userUtilities';
import SchoolBasedPermissions from './SchoolBasedPermissions/SchoolBasedPermissions';
import './UserEdit.css';

const UserEdit = (): ReactElement => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectUserLoading);
  const details: UserEditViewModel = useAppSelector(selectEditUserDetails);
  const [error, setError] = useState<ApiErrorModel | undefined>(undefined);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const userIsStateAdmin =
    details.user.userType === UserType.State && details.user.isAdmin;

  useEffect(() => {
    let promise: unknown = undefined;
    if (userId) {
      promise = dispatch(fetchEditUserDetailsById(userId));
    }
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch, userId]);

  const saveUpdates = async (values: UserEditFormModel): Promise<void> => {
    setIsUpdating(true);

    if (userId) {
      const savingModel = mapFormToEditModel(
        values,
        details.assignableSchoolBasedPermissions.map((domain) => domain.name),
        details.assignableSchools
      );

      const result = await updateUser(userId, savingModel);
      if (isValidCode(result.status)) {
        setError(undefined);
        navigate(appRoutePaths.UserDetail(userId));
      } else {
        setError(result.error);
      }
    }

    setIsUpdating(false);
  };

  const handleReset = (resetFunc: Function): void => {
    setError(undefined);
    resetFunc(mapToFormModel(details));
  };

  const permissionOptions = details.assignableRoles.map(
    (permission): SelectOption => ({
      text: getRoleDisplayText(permission),
      value: permission,
      isDisabled: userIsStateAdmin,
    })
  );

  const schoolOptions: SelectOption[] = details.assignableSchools.map(
    (school) => ({
      text: `${school.name} (${school.schoolId})`,
      value: school.schoolId,
    })
  );

  const disableSubmit = (): boolean => {
    return isLoading || isUpdating;
  };

  return (
    <NavigationPage
      heading={'Edit User'}
      pageClass="edit-user"
      isLoading={isLoading}
      loadingDataId="edit-user-loader"
      loadingText="Loading user"
      contentClass="user-detail-layout"
      APIError={error}
      backBarLocation={appRoutePaths.UserDetail(userId)}
    >
      <Panel panelType={PanelType.INFO} heading="General Information">
        <LabelAndValue
          cypressDataId="user-name"
          testId="user-name-value"
          displayName="Name"
          value={details.user.name}
        />
        <LabelAndValue
          cypressDataId="user-email"
          testId="user-email-value"
          displayName="Email"
          value={details.user.email}
        />
        <LabelAndValue
          cypressDataId="user-district-access"
          testId="user-district-value"
          displayName="District"
          value={details.user.currentDistrict?.name}
        />
      </Panel>
      {details.user.currentDistrict?.districtId ? (
        <Formik
          validateOnChange={true}
          validateOnMount={true}
          initialValues={mapToFormModel(details)}
          onSubmit={async (values) => {
            await saveUpdates(values);
          }}
        >
          {({ values, resetForm, setValues }) => (
            <>
              <Form>
                {details.assignableRoles.length > 0 && (
                  <div className="assignment-section">
                    <Panel
                      panelType={PanelType.INFO}
                      heading="Global Permissions"
                    >
                      <div data-testid="permission-checklist">
                        <FormCheckboxList
                          addAllCheckbox={
                            permissionOptions.length > 1 && !userIsStateAdmin
                          }
                          field={'permissions'}
                          options={permissionOptions}
                        />
                      </div>
                    </Panel>
                  </div>
                )}
                <SchoolBasedPermissions
                  model={details}
                  schoolOptions={schoolOptions}
                  clearParentError={
                    /* istanbul ignore next */
                    () => setError(undefined)
                  }
                />
                <div className="action-row">
                  <ActionButton
                    onClick={() => handleReset(resetForm)}
                    dataTestId="edit-reset"
                    cypressDataId="edit-reset"
                    tooltipText="Reset Form"
                  >
                    <span>Reset</span>
                  </ActionButton>
                  <ActionButton
                    buttonType="submit"
                    classes="button--secondary update-user-button"
                    onClick={() => noop()}
                    dataTestId="edit-submit"
                    disabled={disableSubmit()}
                    cypressDataId="edit-submit"
                    tooltipText="Update User"
                    loading={isUpdating}
                  >
                    <span>Update User</span>
                  </ActionButton>
                </div>
              </Form>
              {
                /* istanbul ignore next */
                GLOBAL_DEBUG_MODE && (
                  <pre>FORM Values: {JSON.stringify(values, null, 2)}</pre>
                )
              }
            </>
          )}
        </Formik>
      ) : (
        <div className="empty--list-text">
          {/* Needed if user tries to access page by URL */}
          <p>No district assigned. Register user for a district.</p>
        </div>
      )}
    </NavigationPage>
  );
};

export default UserEdit;
