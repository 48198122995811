import { VALIDATION_MESSAGE } from '../ValidationMessage';
import yup from './CustomValidation/yup-extended';

// Since all dropdowns have a heriachy (except status), we can just'
// force the final items selected (CourseSection) to be required
export const NonPublicStudentEntryScheduleValidationSchema = yup
  .object()
  .shape({
    courseCode: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
    schoolIdentifier: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
    teacher: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
    courseSection: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
    status: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  });
