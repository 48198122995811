import { ReactElement } from 'react';

const DistrictCertificationGrid = (): ReactElement => {
  return (
    <div>
      <table cellSpacing={0} className="page-view-table">
        <thead>
          <tr data-testid="grid-headers">
            <th>LEA Number</th>
            <th>District Name</th>
            <th>Certification Date</th>
            <th>Certified By</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
  );
};

export default DistrictCertificationGrid;
