/* eslint-disable max-len */
import { ReactElement, useEffect, useState } from 'react';
import CustomFormTextbox from '../../../../components/FormInputs/CustomFormTextbox/CustomFormTextbox';
import FormCheckboxList from '../../../../components/FormInputs/FormCheckboxList/FormCheckboxList';
import FormDateSelector from '../../../../components/FormInputs/FormDateSelector/FormDateSelector';
import FormDropdown from '../../../../components/FormInputs/FormDropdown/FormDropdown';
import FormRadioList from '../../../../components/FormInputs/FormRadioList/FormRadioList';
import FormTextbox from '../../../../components/FormInputs/FormTextbox/FormTextbox';
import ManualInputPage from '../../../../components/ManualInputPage/ManualInputPage';
import Panel from '../../../../components/Panel/Panel';
import { useAppSelector } from '../../../../redux/hooks';
import {
  clearSchoolManagement,
  saveSchoolManagement,
  selectSchoolManagementDataEntryMode,
  selectSchoolManagementFormViewModel,
  selectSchoolManagementLoadingFormViewModel,
  selectSchoolManagementLoadingSchoolManagement,
  selectSchoolManagementSchoolManagement,
  setSchoolManagementDataEntryMode,
} from '../../../../redux/slices/schoolManagementSlice';
import {
  fetchSchoolManagementById,
  fetchSchoolManagementFormViewModel,
} from '../../../../redux/thunks/schoolManagementThunks';
import { getCurrentUserAccess } from '../../../../services/base.service';
import { appRoutePaths } from '../../../../services/route.service';
import {
  createSchoolManagement,
  updateSchoolManagement,
} from '../../../../services/schoolManagement.service';
import { GLOBAL_DEBUG_MODE } from '../../../../testing/debugFunctions';
import { DataEntryMode } from '../../../../types/DataEntryMode';
import { Tabs_DISTRICT_SCHOOL } from '../../../../types/NavigationTabs';
import { PanelType } from '../../../../types/PanelType';
import { FormDropdownChangeEventConfig } from '../../../../types/propTypes/FormDropdownPropTypes';
import { FormRadioListOrientation } from '../../../../types/propTypes/FormRadioListPropTypes';
import { FormTextboxChangeEventConfig } from '../../../../types/propTypes/FormTextboxPropTypes';
import { ManualInputCrudAction } from '../../../../types/propTypes/ManualInputPagePropTypes';
import { SchoolManagementFormViewModel } from '../../../../types/SchoolManagementFormViewModel';
import { SchoolManagementModel } from '../../../../types/SchoolManagementModel';
import { SchoolManagementValidationSchema } from '../../../../types/Validation/SchoolManagementValidationSchema';
import { buildUserAccessNavigationTabItems } from '../../../../utilities/routeUtilities';
import {
  getCountyAnsiCodeFromOptions,
  isStateAdmin,
} from '../../../../utilities/userUtilities';
import './SchoolManagementInput.css';

const SchoolManagementInput = (): ReactElement => {
  const DEFAULT_STATE = 'Mississippi';

  const schoolManagementModel: SchoolManagementModel = useAppSelector(
    selectSchoolManagementSchoolManagement
  );
  const formViewModel: SchoolManagementFormViewModel = useAppSelector(
    selectSchoolManagementFormViewModel
  );
  const isLoadingFormViewModel = useAppSelector(
    selectSchoolManagementLoadingFormViewModel
  );
  const isLoadingSchoolManagementModel = useAppSelector(
    selectSchoolManagementLoadingSchoolManagement
  );
  const dataEntryMode: DataEntryMode = useAppSelector(
    selectSchoolManagementDataEntryMode
  );
  const isLoading = isLoadingSchoolManagementModel || isLoadingFormViewModel;
  const supportedNonGenericErrors = [
    {
      statusCode: 400,
    },
    {
      statusCode: 409,
      howToFix:
        'The provided School Management ID is already in the system. Use another value, or return to the list and edit the existing School Management.',
    },
  ];

  const [formValues, setFormValues] = useState<SchoolManagementModel>(
    schoolManagementModel
  );
  const [
    arePhysicalAddressFieldsRequired,
    setArePhysicalAddressFieldsRequired,
  ] = useState(false);

  const [isReportedFederallyDisabled, setIsReportedFederallyDisabled] =
    useState(false);

  const [isCharterSchoolTypeDisabled, setIsCharterSchoolTypeDisabled] =
    useState(false);

  const getRequiredPhysicalAddressLabel = (): string => {
    return arePhysicalAddressFieldsRequired ? 'forced-required-label' : '';
  };

  const updatePhysicalAddressRequiredFlag = async (
    config: FormTextboxChangeEventConfig
  ): Promise<void> => {
    const isRequired =
      config.value === '' &&
      schoolManagementModel.physicalAddressStreetNumberAndName === '' &&
      schoolManagementModel.physicalCity === '' &&
      schoolManagementModel.physicalState === '' &&
      schoolManagementModel.physicalZipCode === ''
        ? false
        : true;
    setArePhysicalAddressFieldsRequired(isRequired);
  };

  const [areMailingAddressFieldsRequired, setAreMailingAddressFieldsRequired] =
    useState(false);

  const updateMailingAddressRequiredFlag = async (
    config: FormTextboxChangeEventConfig
  ): Promise<void> => {
    const isRequired =
      config.value === '' &&
      formValues.mailingAddressStreetNumberAndName === '' &&
      formValues.mailingCity === '' &&
      formValues.mailingState === '' &&
      formValues.mailingZipCode === ''
        ? false
        : true;
    setAreMailingAddressFieldsRequired(isRequired);
  };

  const getRequiredMailingAddressLabel = (): string => {
    return areMailingAddressFieldsRequired ? 'forced-required-label' : '';
  };

  const [isShowPhysicalCounty, setIsShowPhysicalCounty] = useState(false);
  const [isShowMailingCounty, setIsShowMailingCounty] = useState(false);

  const updateShowPhysicalCounty = async (
    config: FormDropdownChangeEventConfig
  ): Promise<void> => {
    const showCounty = config.value === DEFAULT_STATE ? true : false;
    setIsShowPhysicalCounty(showCounty);
    setArePhysicalAddressFieldsRequired(true);
  };

  const updatePhysicalCountyAnsiCode = async (
    config: FormDropdownChangeEventConfig
  ): Promise<void> => {
    const physicalCountyAnsiCode = getCountyAnsiCodeFromOptions(
      formViewModel.countyAnsiCodeOptions,
      config
    );
    /* istanbul ignore next */
    if (config.setValueHook && physicalCountyAnsiCode) {
      config.setValueHook('physicalCountyAnsiCode', physicalCountyAnsiCode);
    }
  };

  useEffect(() => {
    if (schoolManagementModel.physicalState === DEFAULT_STATE) {
      setIsShowPhysicalCounty(true);
    }
    if (schoolManagementModel.mailingState === DEFAULT_STATE) {
      setIsShowMailingCounty(true);
    }
  }, [schoolManagementModel.physicalState, schoolManagementModel.mailingState]);

  useEffect(() => {
    const isPhysicalAddressRequired =
      formValues.physicalAddressStreetNumberAndName === '' &&
      formValues.physicalCity === '' &&
      formValues.physicalState === '' &&
      formValues.physicalZipCode === ''
        ? false
        : true;
    setArePhysicalAddressFieldsRequired(isPhysicalAddressRequired);

    const isMailingAddressRequired =
      formValues.mailingAddressStreetNumberAndName === '' &&
      formValues.mailingCity === '' &&
      formValues.mailingState === '' &&
      formValues.mailingZipCode === ''
        ? false
        : true;
    setAreMailingAddressFieldsRequired(isMailingAddressRequired);
  }, [formValues, schoolManagementModel]);

  const updateShowMailingCounty = async (
    config: FormDropdownChangeEventConfig
  ): Promise<void> => {
    const showCounty = config.value === DEFAULT_STATE ? true : false;
    setIsShowMailingCounty(showCounty);
    setAreMailingAddressFieldsRequired(true);
  };

  const updateMailingCountyAnsiCode = async (
    config: FormDropdownChangeEventConfig
  ): Promise<void> => {
    const mailingCountyAnsiCode = getCountyAnsiCodeFromOptions(
      formViewModel.countyAnsiCodeOptions,
      config
    );
    /* istanbul ignore next */
    if (config.setValueHook && mailingCountyAnsiCode) {
      config.setValueHook('mailingCountyAnsiCode', mailingCountyAnsiCode);
    }
  };

  /* istanbul ignore next */
  const getDistrictDisplay = (): string => {
    if (dataEntryMode === DataEntryMode.EDIT) {
      return schoolManagementModel.schoolIdentifierLeaPart;
    } else {
      const user = getCurrentUserAccess();
      const districtId = user?.currentDistrict?.districtId || '';

      return `${districtId}`;
    }
  };

  /* istanbul ignore next */
  const getDistrictType = (): string => {
    const user = getCurrentUserAccess();
    const districtType = user?.currentDistrict?.type || '';

    const charterSchoolIndicator =
      districtType === 'Independent Charter District' ? 'Yes' : 'No';
    return `${charterSchoolIndicator}`;
  };

  /* istanbul ignore next */
  const getDistrictName = (): string => {
    if (dataEntryMode === DataEntryMode.EDIT) {
      return schoolManagementModel.leaName;
    } else {
      const user = getCurrentUserAccess();
      const districtName = user?.currentDistrict?.name || '';

      return `${districtName}`;
    }
  };

  const updateReportedFederally = async (
    config: FormTextboxChangeEventConfig
  ): Promise<void> => {
    let reportedFederally =
      config.value === '500' || config.value === '200' ? 'No' : 'Yes';
    let reportedFederallyDisabled = reportedFederally === 'No' ? true : false;

    /* istanbul ignore next */
    if (config.setValueHook && reportedFederally) {
      if (dataEntryMode === DataEntryMode.EDIT) {
        reportedFederallyDisabled = false;
      }
      setIsReportedFederallyDisabled(reportedFederallyDisabled);

      if (reportedFederally === 'Yes') {
        reportedFederally = '';
      }
      config.setValueHook('reportedFederally', reportedFederally);
    }
  };

  const intitalValues = {
    ...schoolManagementModel,
    leaIdentifier: getDistrictDisplay(),
    leaName: getDistrictName(),
    schoolIdentifierLeaPart: getDistrictDisplay(),
    charterSchool: getDistrictType(),
    charterSchoolType: getDistrictType() === 'No' ? 'Not a Charter School' : '',
  };

  useEffect(() => {
    let charterSchoolTypeDisabledFlag =
      getDistrictType() === 'No' ? true : false;

    if (dataEntryMode === DataEntryMode.EDIT) {
      charterSchoolTypeDisabledFlag = false;
    }

    setIsCharterSchoolTypeDisabled(charterSchoolTypeDisabledFlag);
  }, [dataEntryMode]);

  useEffect(() => {
    let reportedFederallyDisabledFlag =
      schoolManagementModel.schoolIdentifierSchoolPart === '500' ||
      schoolManagementModel.schoolIdentifierSchoolPart === '200'
        ? true
        : false;

    if (dataEntryMode === DataEntryMode.EDIT) {
      reportedFederallyDisabledFlag = false;
    }

    setIsReportedFederallyDisabled(reportedFederallyDisabledFlag);
  }, [schoolManagementModel.schoolIdentifierSchoolPart, dataEntryMode]);

  useEffect(() => {
    if (
      schoolManagementModel.mailingAddressStreetNumberAndName !== '' ||
      schoolManagementModel.mailingCity !== '' ||
      schoolManagementModel.mailingState !== '' ||
      schoolManagementModel.mailingZipCode !== ''
    ) {
      setAreMailingAddressFieldsRequired(true);
    }
    if (
      schoolManagementModel.physicalAddressStreetNumberAndName !== '' ||
      schoolManagementModel.physicalCity !== '' ||
      schoolManagementModel.physicalState !== '' ||
      schoolManagementModel.physicalZipCode !== ''
    ) {
      setArePhysicalAddressFieldsRequired(true);
    }
  }, [
    schoolManagementModel.mailingAddressStreetNumberAndName,
    schoolManagementModel.mailingCity,
    schoolManagementModel.mailingState,
    schoolManagementModel.mailingZipCode,
    schoolManagementModel.physicalAddressStreetNumberAndName,
    schoolManagementModel.physicalCity,
    schoolManagementModel.physicalState,
    schoolManagementModel.physicalZipCode,
  ]);

  return (
    <ManualInputPage<SchoolManagementModel, SchoolManagementFormViewModel>
      actions={ManualInputCrudAction.CREATE_AND_EDIT}
      loadingOptions={{
        isLoading: isLoading,
        loadingDataId: 'school-management-input-loader',
      }}
      navigationTabs={buildUserAccessNavigationTabItems(Tabs_DISTRICT_SCHOOL)}
      classes={{ pageClass: 'data-input--school-management--input' }}
      parameterOptions={{
        routeParameterName: 'schoolIdentifier',
        identityName: schoolManagementModel.id,
      }}
      routes={{
        backBarRoute: appRoutePaths.SchoolManagementList,
        cancelRoute: appRoutePaths.SchoolManagementList,
        createSuccessRoute: appRoutePaths.SchoolManagementInput,
      }}
      dataFunctions={{
        loadDataDetailById: fetchSchoolManagementById,
        loadFormDetail: fetchSchoolManagementFormViewModel,
        setEntryMode: setSchoolManagementDataEntryMode,
        setData: saveSchoolManagement,
        clearData: clearSchoolManagement,
        onCreate: createSchoolManagement,
        onUpdate: updateSchoolManagement,
      }}
      formDetails={{
        validationSchema: SchoolManagementValidationSchema,
        initialFormValues: intitalValues,
        isFormModelEmpty: !formViewModel.gradeOptions?.length,
        onFormValuesChange: (values) => {
          setFormValues(values);
        },
      }}
      config={{
        dataEntryMode: dataEntryMode,
        objectVerbiage: 'School Management',
        pageHeader: 'LEA & School Info',
        supportedNonGenericErrors: supportedNonGenericErrors,
      }}
      DEV_MODE={GLOBAL_DEBUG_MODE}
    >
      <div data-testid="school-management-input-child">
        <Panel panelType={PanelType.INFO} heading="School Information">
          <div className="field-row">
            <FormTextbox
              displayName="Name of Institution"
              field="schoolName"
              validationSchema={SchoolManagementValidationSchema}
              disabled={!isStateAdmin()}
            />
            <CustomFormTextbox
              displayName="School Identifier"
              field1="schoolIdentifierLeaPart"
              field2="schoolIdentifierSchoolPart"
              validationSchema={SchoolManagementValidationSchema}
              onChangeEvent={updateReportedFederally}
              disabled={!isStateAdmin()}
            />
            <FormTextbox
              displayName="LEA Identifier"
              field="leaIdentifier"
              disabled={true}
            />
          </div>

          <div className="field-row field-row-grid-2-1-1-1">
            <FormDropdown
              displayName="School Type"
              field="schoolType"
              options={formViewModel.schoolTypeOptions}
              validationSchema={SchoolManagementValidationSchema}
              disabled={!isStateAdmin()}
            />
            <FormRadioList
              displayName="Reported Federally"
              field="reportedFederally"
              options={[
                { text: 'Yes', value: 'Yes' },
                { text: 'No', value: 'No' },
              ]}
              optionOrientation={FormRadioListOrientation.Horizontal}
              validationSchema={SchoolManagementValidationSchema}
              disabled={isReportedFederallyDisabled || !isStateAdmin()}
            />

            <FormTextbox
              displayName="ACT Identifier"
              field="actIdentifier"
              validationSchema={SchoolManagementValidationSchema}
              disabled={!isStateAdmin()}
            />
            <FormTextbox
              displayName="NCES Identifier"
              field="ncesIdentifier"
              validationSchema={SchoolManagementValidationSchema}
              disabled={!isStateAdmin()}
            />
          </div>
          <div className="field-row">
            <FormRadioList
              displayName="Charter School"
              field="charterSchool"
              options={[
                { text: 'Yes', value: 'Yes' },
                { text: 'No', value: 'No' },
              ]}
              optionOrientation={FormRadioListOrientation.Horizontal}
              validationSchema={SchoolManagementValidationSchema}
              disabled={dataEntryMode === DataEntryMode.NEW || !isStateAdmin()}
            />
            <FormDropdown
              displayName="Charter School Type"
              field="charterSchoolType"
              options={formViewModel.charterSchoolTypeOptions}
              validationSchema={SchoolManagementValidationSchema}
              disabled={isCharterSchoolTypeDisabled || !isStateAdmin()}
            />
            <FormDropdown
              displayName="School Operational Status"
              field="schoolOperationalStatus"
              options={formViewModel.schoolOperationalStatusOptions}
              validationSchema={SchoolManagementValidationSchema}
              disabled={!isStateAdmin()}
            />

            <FormDateSelector
              displayName="Operational Status Effective Date"
              field="operationalStatusEffectiveDate"
              validationSchema={SchoolManagementValidationSchema}
              disabled={!isStateAdmin()}
            />
          </div>

          <div className="field-row">
            <FormTextbox
              displayName="Website"
              field="website"
              validationSchema={SchoolManagementValidationSchema}
              disabled={!isStateAdmin()}
            />
            <FormTextbox
              displayName="Telephone Number"
              field="phone"
              validationSchema={SchoolManagementValidationSchema}
              disabled={!isStateAdmin()}
            />
          </div>
          <div className="field-row">
            <FormCheckboxList
              displayName="Grades"
              field="grades"
              options={formViewModel.gradeOptions}
              addAllCheckbox={true}
              classConfig={{ containerClasses: 'grades-container' }}
              validationSchema={SchoolManagementValidationSchema}
              disabled={!isStateAdmin()}
            />
            <FormRadioList
              displayName="Magnet School"
              field="magnetSchool"
              options={[
                { text: 'Yes', value: 'Yes' },
                { text: 'No', value: 'No' },
              ]}
              optionOrientation={FormRadioListOrientation.Horizontal}
              validationSchema={SchoolManagementValidationSchema}
              disabled={!isStateAdmin()}
            />
            <FormRadioList
              displayName="Virtual School"
              field="virtualSchool"
              options={[
                { text: 'Yes', value: 'Yes' },
                { text: 'No', value: 'No' },
              ]}
              optionOrientation={FormRadioListOrientation.Horizontal}
              validationSchema={SchoolManagementValidationSchema}
              disabled={!isStateAdmin()}
            />
          </div>
        </Panel>
        <Panel panelType={PanelType.INFO} heading="Physical Address">
          <div className="field-row">
            <FormTextbox
              displayName="Address Street Number and Name"
              field="physicalAddressStreetNumberAndName"
              classConfig={{ labelClasses: getRequiredPhysicalAddressLabel() }}
              validationSchema={SchoolManagementValidationSchema}
              onChangeEvent={updatePhysicalAddressRequiredFlag}
              disabled={!isStateAdmin()}
            />
            <FormTextbox
              displayName="Apt., Suite, Unit, Building, Floor, Etc."
              field="physicalAptSuiteBuildingFloorNumber"
              validationSchema={SchoolManagementValidationSchema}
              disabled={!isStateAdmin()}
            />
          </div>
          <div className="field-row">
            <FormTextbox
              displayName="Physical City"
              field="physicalCity"
              classConfig={{ labelClasses: getRequiredPhysicalAddressLabel() }}
              validationSchema={SchoolManagementValidationSchema}
              onChangeEvent={updatePhysicalAddressRequiredFlag}
              disabled={!isStateAdmin()}
            />
            <FormDropdown
              displayName="State"
              field="physicalState"
              options={formViewModel.stateOptions}
              classConfig={{ labelClasses: getRequiredPhysicalAddressLabel() }}
              validationSchema={SchoolManagementValidationSchema}
              onChangeEvent={updateShowPhysicalCounty}
              disabled={!isStateAdmin()}
            />
            <FormTextbox
              displayName="Zip Code"
              field="physicalZipCode"
              classConfig={{ labelClasses: getRequiredPhysicalAddressLabel() }}
              validationSchema={SchoolManagementValidationSchema}
              onChangeEvent={updatePhysicalAddressRequiredFlag}
              disabled={!isStateAdmin()}
            />
          </div>
          {isShowPhysicalCounty && (
            <div className="field-row">
              <FormDropdown
                displayName="County"
                field="physicalCounty"
                options={formViewModel.countyOptions}
                classConfig={{
                  labelClasses: getRequiredPhysicalAddressLabel(),
                }}
                validationSchema={SchoolManagementValidationSchema}
                onChangeEvent={updatePhysicalCountyAnsiCode}
                disabled={!isStateAdmin()}
              />
              <FormTextbox
                displayName="County ANSI Code"
                field="physicalCountyAnsiCode"
                disabled={true}
              />
            </div>
          )}
        </Panel>
        <Panel panelType={PanelType.INFO} heading="Mailing Address">
          <div className="field-row">
            <FormTextbox
              displayName="Address Street Number and Name"
              field="mailingAddressStreetNumberAndName"
              classConfig={{ labelClasses: getRequiredMailingAddressLabel() }}
              validationSchema={SchoolManagementValidationSchema}
              onChangeEvent={updateMailingAddressRequiredFlag}
              disabled={!isStateAdmin()}
            />
            <FormTextbox
              displayName="Apt., Suite, Unit, Building, Floor, Etc."
              field="mailingAptSuiteBuildingFloorNumber"
              validationSchema={SchoolManagementValidationSchema}
              disabled={!isStateAdmin()}
            />
          </div>
          <div className="field-row">
            <FormTextbox
              displayName="City"
              field="mailingCity"
              classConfig={{ labelClasses: getRequiredMailingAddressLabel() }}
              validationSchema={SchoolManagementValidationSchema}
              onChangeEvent={updateMailingAddressRequiredFlag}
              disabled={!isStateAdmin()}
            />
            <FormDropdown
              displayName="State"
              field="mailingState"
              options={formViewModel.stateOptions}
              classConfig={{ labelClasses: getRequiredMailingAddressLabel() }}
              validationSchema={SchoolManagementValidationSchema}
              onChangeEvent={updateShowMailingCounty}
              disabled={!isStateAdmin()}
            />
            <FormTextbox
              displayName="Zip Code"
              field="mailingZipCode"
              classConfig={{ labelClasses: getRequiredMailingAddressLabel() }}
              validationSchema={SchoolManagementValidationSchema}
              onChangeEvent={updateMailingAddressRequiredFlag}
              disabled={!isStateAdmin()}
            />
          </div>
          {isShowMailingCounty && (
            <div className="field-row">
              <FormDropdown
                displayName="County"
                field="mailingCounty"
                options={formViewModel.countyOptions}
                classConfig={{ labelClasses: getRequiredMailingAddressLabel() }}
                validationSchema={SchoolManagementValidationSchema}
                onChangeEvent={updateMailingCountyAnsiCode}
                disabled={!isStateAdmin()}
              />
              <FormTextbox
                displayName="County ANSI Code"
                field="mailingCountyAnsiCode"
                disabled={true}
              />
            </div>
          )}
        </Panel>
      </div>
    </ManualInputPage>
  );
};

export default SchoolManagementInput;
