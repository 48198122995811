import { DistrictModelExtended } from './DistrictModel';
import { SelectOption } from './SelectOption';

export enum NPSESchoolType {
  CTE = 'CTE',
  SPED = 'SPED',
}

export enum OutcomeType {
  EarlyChildhoodValues = 'EarlyChildhood',
  SchoolAgeValues = 'SchoolAge',
}

export interface EducationalEnvironmentModel {
  id: string;
  name: string;
  outcomeType: OutcomeType;
}

export interface NonPublicStudentFormViewModel {
  defaultSchoolYear: string;
  districts: DistrictModelExtended[];
  educationEnvironments: EducationalEnvironmentModel[];

  schoolTypeOptions: SelectOption[];
  genderOptions: SelectOption[];
  ethnicityOptions: SelectOption[];
  raceOptions: SelectOption[];
  specialEducationOptions: SelectOption[];
  englishLearnerOptions: SelectOption[];
  enrollmentStatusOptions: SelectOption[];
  gradeLevelOptions: SelectOption[];
  singleParentOptions: SelectOption[];
  displacedHomemakerOptions: SelectOption[];
  placementOptions: SelectOption[];
  scdOptions: SelectOption[];
  primaryDisabilityOptions: SelectOption[];
  secondaryDisabilityOptions: SelectOption[];
  relatedServicesOptions: SelectOption[];
  projectedHSDiplomaTypeOptions: SelectOption[];
  outcomeExitScoreOptions: SelectOption[];
  outcomeEntryScoreOptions: SelectOption[];
  outcomeProgressOptions: SelectOption[];
}
